.header-main{
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    opacity: 0;
    animation: fadeIn 0.6s ease-out 0.4s forwards;
    .section{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    .left{
      width: calc(100% - 150px);
      .back-btn{
       margin-right: 0.5rem;
      }
      .header-title{
        color: white;
        font-family: 'Baloo 2';
        font-size: 1.3rem;
        font-weight: 800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .score-box,.microphone-box{
    min-width: 5.6rem;
    height: 2.5rem;
    background-color: red;
    border-radius: 5.6rem;
    position: relative;
    box-shadow: 0 4px 6px 0 rgba(70, 133, 242, 0.3);
    margin-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Baloo 2';
    font-size: 1.12rem;
    font-weight: 800;
    color: #fff;
   .shine {
      position: absolute;
      top: 5px;
      left: 10px;
      min-width: 3.4rem;
      border-radius: 1.25rem;
      height: 15px;
    }
    .coin-icon{
      width: 1.56rem;
      margin-right: 5px;
      margin-left: 10px;
    }
  }
  .microphone-box{
    height: 2.5rem;
    min-width: 2.5rem;
    width: 2.5rem;
    justify-content: center;
    align-items: center;
    .shine {
      left: 5px;
      min-width: 1.25rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }


@keyframes fadeIn{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}