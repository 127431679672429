.header-wrapper{
  // height: 6rem;
  border-radius: 0 0 1.8rem 1.8rem ;
  padding:0.6rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
}