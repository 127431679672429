.multiWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textLevel {
    font-size: 14px;
    display: block;
    text-align: right;
    margin-bottom: 20px !important;
}

.contentsWrapper {
    display: flex;
    justify-content: space-between;
}

.marginTop20 {
    margin-top: 20px;
}


.textLevel>input {
    margin-left: 10px;
}