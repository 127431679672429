.progress-wrapper{
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .progress-bar{
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      .trail{
        position: relative;
        width: 100%;
        height: 0.8rem;
        border-radius: 0.8rem;
      }
      .progress{
        position: absolute;
        top: 0;
        left: 0;
        height: 0.8rem;
        border-radius: 0.8rem;
        box-shadow: 1px 0 1px 0 rgba(139, 237, 255, 0.32), inset 0 1px 1px 0 rgba(255, 255, 255, 0.5);
        background-image: linear-gradient(to bottom, #8deffe, #3eacfc);
        transition: width 0.5s linear;
     }
    }
    .bookmark{
      margin-top: 5px;
    }
    .tag{
      position: absolute;
      top: -3px;
      right: -3px;
      z-index: 500;
    }
  }