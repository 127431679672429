.label-outer-box {
  border: none;
  .box {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 10px;
    min-width: 650px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(#efefef, #eee);
    .header-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: inherit;
      border: none;
      padding: 5px 10px;
      .header-text {
        color: #000000;
      }
      .delete-btn {
        border: 2px solid red;
        border-radius: 50%;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        .collapse-icon {
          width: 40px;
          height: 40px;
        }
      }

      .delete-icon {
        color: red;
        cursor: pointer;
      }
    }
    .header-bottom {
      margin-bottom: 10px;
    }
  }
}
