html, body {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}

#root {
	height: 100%;
}

.errorDiv {
	display: block;
	width: 80%;
	margin: auto;
}

.waiting {
	padding: 32px;
}
