.progress-holder-v2 {
  width: 35%;
  padding: 1rem;
  margin-top: 0.4rem;

  .RSPBprogressBar {
    height: 0.15rem;
    transition: all 0.5s;
    background-color: #9ce5d8;

    .progress-dot-v2 {
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 50%;
      background: #9ce5d8;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;

      .img-container {
        height: 0.93rem;
        width: 0.93rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 100%;
          width: 100%;
        }
      }

      .current-inner {
        height: 0.93rem;
        width: 0.93rem;
        border-radius: 50%;
        background: #faf5fc;
      }
    }
  }
}
