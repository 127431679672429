.slide-up-animation{
  animation: slideUp 0.4s ease-out forwards;
}
.dummy-buddy{
  position: absolute; 
  bottom: 5vh;
  right: 30px; 
  width: 60px;
  height: 60px; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
}

.editor-page-navigation-button{
  position: absolute; 
  bottom: 2vh;
  right: 50%; 
  width: 50%;
  transform: translateX(50%);
  display: flex; 
  align-items: center; 
  justify-content: space-between; 
  .btn{
    padding: 8px 20px;
    border-radius: 31px;
    box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.14), inset 0 8px 6px 0 rgba(255, 255, 255, 0.25);
    border: solid 2px #caefeb;
    background-color: #fff;
    cursor: pointer;
  }
}
.readAloudContainer {
  padding: 1rem;
  align-items: flex-start;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-image: linear-gradient(to bottom, #d2f0ee, rgba(#d2f0ee, 0.2));
  .content-wrapper{
    height: calc(100vh - 4rem);
    width: 100%;
  }
  .pageSize {
    position: fixed;
    bottom: 1rem;
    background: #333;
    color: #fff;
    padding: 0.5rem;
    right: 16px;
    border-radius: 4px;
  }
  .readText {
    color: #333;
    height: 100vh;
  }
  .wave{
    position:absolute;
    bottom: 0.5rem;
    left: 0;
    width: 100%;
    z-index: -1;
  }
}


@keyframes slideUp{
  0%{
    margin-top: 70vh;
    opacity: 0;
  }
  100%{
    margin-top:0;
    opacity: 1;
  }
}

@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

