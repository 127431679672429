.editor-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .editorBox {
    display: flex;
    align-items: flex-start;
    label {
      width: 100px;
      overflow: hidden;
      word-break: break-word;
    }
    .text {
      font-weight: 700;
      text-align: right;
      padding: 6px 9px;
    }
  }
}
