.textLevel {
    font-size: 14px;
    display: block;
    margin: 0px 10px 0px 10px;
}

.queryform {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.fieldWrapper {
    display: flex;
    flex-flow: wrap;
    width: 13em;
}

.optionWrapper {
    margin: 10px;
}