@keyframes slideLeft{
  100%{
    margin-left:0;
  }
}

@keyframes fadeIn{
  0%{
    opacity: 0;
  }
  100%{
    opacity:1;
  }
}
.questions-wrapper{
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #d2f0ee, rgba(#d2f0ee, 0.2));
  height: 100vh;
  animation: slideLeft 0.3s ease-out forwards;
  .dots-container{
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 20px;
    background: none;
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards 0.3s;
    .dot{
      border-radius: 100%;
      width: 8px;
      height: 8px;
      background-color: #a6d1db;
      &.active{
        background-color: #7bbecd;
        width: 10px;
        height: 10px;
      }
    }
  }
  .wave{
    position:fixed;
    bottom: 0.5rem;
    left: 0;
    width: 100%;
    z-index: -1;
  }
}

.question-container {
    padding: 0 1rem;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
}

.question-wrapper{
    .question-image{
      width: 100%;
      height: 32vh;
      border-radius: 16px;
      object-fit: cover;
    }
    .content-body{
      display: flex;
      flex-direction: column;
      text-align: left;
      .instruction{
          line-height: 30px;
          font-size: 1.188rem;
          font-weight: 600;
          color: #000;
          margin-top: 0.8rem;
      }
      .main-question{
        margin-top: 4px;
        font-size: 0.938rem;
        font-weight: 500;
        color: #000;
      }
      .options{
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .option{
          animation: slideLeft 0.3s ease-out forwards;
        }
      }
    }
  }