.editor-checkbox-container {
  display: flex;
  align-items: center;
  padding: 16px;
  background: gray;
  .checkbox-style {
    width: 20px;
    height: 20px;
  }
}

.editor-radio-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: gray;
  .radio-group-label {
    padding-bottom: 10px;
  }
  .radio-style {
    width: 15px;
    height: 15px;
  }
}

.editor-block {
  min-width: 650px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: lightgray;
  padding: 10px;
}

.button-block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;
  .button {
    white-space: nowrap;
    display: inline-block;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 20px;
    background-image: linear-gradient(#fff, #d5d5d5);
    border: 1px solid #d5d5d5;
    color: #000000;
  }
}
.selectBox {
  label {
    margin: 5px 0px;
    font-size: 16px;
    font-family: 'Gotham-Bold';
    display: block;
    text-align: left;
    color: #000000;
    font-weight: 700;
  }
  select {
    width: 100%;
    height: 3rem;
  }
}

.content-type-select-container {
  select {
    width: 400px;
    height: 3rem;
  }
}

.editorBox {
  .video-input-wrapper {
    text-align: left;
    margin: 10px 10px;
    .heading {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    label {
      width: 50px;
      display: inline-block;
    }
    .video-input-style {
      width: 300px;
    }
  }
}
