.slides {
    height: 100vh;
  	// overflow-y: scroll;
  	// scroll-snap-type: y mandatory;
    transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .slide {
    display: flex;
    height: 100vh;
    color: white;
    justify-content: center;
    align-items: center;
    background-size: cover;
    position: relative;
    // scroll-snap-align: start;
  }

