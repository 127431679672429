.projectTree .rst__rowSearchFocus {
	outline: none;
}

.projectTree .rst__rowSearchMatch {
	outline: none;
}

.projectTree .rst__tree > div:first-child {
	height: auto !important;
	width: auto !important;
	overflow: hidden !important;
}

.projectTree .rst__virtualScrollOverride {
	overflow: hidden !important;
}

.projectTree .rst__moveHandle,
.projectTree .rst__loadingHandle {
	background-color: #2196f3;
	border-color: #2196f3;
	flex-shrink: 0;
}

.projectTree .rst__node {
	width: auto !important;
	min-width: auto;
}
