.game-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow: hidden;
  .bg-container {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
