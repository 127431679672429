.splitter-layout {
  .cke_chrome {
    border: 2px solid #d1d1d1;
  }
  .editor-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .submit-btn-wrapper {
      position: absolute;
      top: 50%;
      right: 0%;
      .submit-btn {
        padding: 10px;
        border-radius: 25px;
        background: lightgray;
        border: 2px solid gray;
        cursor: pointer;
      }
    }
  }
  .header-container {
    margin-bottom: 0;
  }
  .game-container {
    height: 100%;
    min-width: 100%;
    position: relative;
  }
}

.cke_chrome {
  min-width: 400px;
}
