.assessment-screen-container{
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
.assessment-wrapper{
    width: 100%;
    border-radius: 0 0 1.8rem 1.8rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 28.12rem;
    z-index: 1200;
    animation: growDown 0.7s ease-out forwards;
    &.growFurther{
      animation: growFurther 0.2s ease-out forwards;
    }
    .assessment-header{
       width: 100%;
       padding:0.6rem;
       padding-left: 1rem;
       padding-bottom:1.5rem;
    }
    .assessment-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
        .title{
          font-family: 'Baloo 2';
          font-weight: bold;
          font-size: 1.8rem;
          line-height: 1.13;
          text-align: center;
          color: #fff;
        }
        .subtitle{
          font-size: 1.125rem;
        }
        .desc{
          margin-top: 0.5rem;
          font-size: 1rem;
        }
        .wave{
          position:absolute;
          width: 100%;
          z-index: -1;
          left: 0;
          bottom: 0;
        }
    }
    .score-div{
      position: relative;
      height: 12.5rem;
      width: 100%;
      animation: zoomIn 0.7s ease-out 0.5s forwards;
      transform: scale(0.1);
      opacity: 0;
      .sunrays{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
      }
      .sunrays-rotate{
        animation: rotateSunRays 8s linear 0.8s infinite;
        // opacity: 0;
      }
      .score-board{
        height: 11.25rem;
        width: 90%;
        border-radius:0.9rem;
        background: linear-gradient(to bottom, #ffffff, #ecfffd);
        align-items: center;
        display: flex;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        z-index: 1200;
        padding: 0.9rem 1.25rem;
        box-shadow: inset 0 -4px 4px 0 rgba(157, 39, 213, 0.12);
        background-image: linear-gradient(to bottom, #fff 0%, #ecfffd 100%);
        box-sizing: border-box;
        border-radius: 1.5rem;
        max-width: 25rem;
        flex-direction: column;
        .details{
          width: 100%;
          justify-content: space-between;
          display: flex;
          .detail{
            text-align: start;
            .detail-title{
              font-family: 'Baloo 2';
              font-size: 0.75rem;
              font-weight: 600;
              line-height: 1.33;
              color: #666;
            }
            .score{
              font-family: "Baloo 2";
              font-size: 1.125rem;
              font-weight: bold;
              line-height: 0.89;
              color: #845de9;
              margin-top: 5px;
            }
            .time-taken{
              font-family: "Baloo 2";
              font-size: 0.75rem;
              font-weight: 600;
              color: #049560;          
              margin-top: 5px;
              & span{
                font-weight: 500;
              }
            }
          }
        }
        .progress-bar-wrapper{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 5px;
          .progress-bar{
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            height: 2rem;
            flex-grow: 0;
            margin: 4px 4px 8px 0;
            padding: 0 6.25rem 0 0;
            border-radius: 2rem;
            background-image: linear-gradient(to bottom, #d5e6ef 0%, #edf4fa 100%);
            position: relative;
            .trail{
              position: absolute;
              left:0;
              height: 0.8rem;
              border-radius: 0.8rem;
              height: 2rem;
              flex-grow: 0;
              border-radius: 2rem;
              box-shadow: inset 0 2px 7px 0 #0c95ce, inset -5px -7px 8px 0 rgba(87, 255, 255, 0.73);
              background-image: linear-gradient(89deg, #38d4ec -38%, #39a5f2 116%);
              .previous-score{
                .pointer-dot{
                   position: absolute;
                   bottom:-2px;
                   transform: translateX(-50%);
                   width: 0.5rem;
                   height: 0.5rem;
                   border-radius: 100%;
                   box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                   border: solid 2px #ffbd12;
                   background-color: #fff;
                }
                .dialogue{
                  position: absolute;
                  bottom:-4.8rem;
                  transform: translateX(-50%);
                  width: 8.31rem;
                  height: 5rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  .dialogue-pointer{
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 10px solid white;
                  }
                  .dialogue-box{
                    border-radius: 10px;
                    width: 8.31rem;
                    height: 2.5rem;
                    flex-grow: 0;
                    padding: 5px;
                    padding-top:10px;
                    box-shadow: 0 4px 12px 0 rgba(72, 45, 145, 0.2);
                    background-color: white;
                    .previous-score-title{
                      font-size: 0.75rem;
                      font-weight: 500;
                      line-height: 1.33;
                      color: #666;
                    }
                    .previous-score-value{
                      font-size: 0.75rem;
                      font-weight: bold;
                      color: #845de9;
                    }
                  }
                }
              }
              .shine{
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                margin-top: 2px;
                width: 80%;
                border-radius: 2rem;
                background-color: rgba(255, 255, 255, 0.21); 
                height: 0.75rem; 
              }
            }
            .progress{
              position: absolute;
              top: 0;
              left: 0;
              height: 0.8rem;
              border-radius: 0.8rem;
              box-shadow: 1px 0 1px 0 rgba(139, 237, 255, 0.32), inset 0 1px 1px 0 rgba(255, 255, 255, 0.5);
              background-image: linear-gradient(to bottom, #8deffe, #3eacfc);
              transition: width 0.5s linear;
           }
          }
          .tag{
            position: absolute;
            top: 0px;
            right: -3px;
            z-index: 500;
            img{
              height: 40px;
              width: 40px;
            }
          }
          .tag-background{
              position: absolute;
              top: -6px;
              right: -5px;
              background-color: red;
              height: 44px;
              width: 44px;
              z-index: 450;
              border-radius: 100%;
          }
        }
      }
      .lower-board{
        margin-top: 3rem;
        z-index: 1000;
        height: 230px;
        padding: 65.4px 13.2px 3px 21.6px;
        box-shadow: inset 0 -4px 4px 0 rgba(157, 39, 213, 0.12);
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 36%, rgba(236, 255, 253, 0.78));
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px;
        .title{
          font-family: "Baloo 2";
          font-size: 18px;
          font-weight: bold;
          line-height: 0.89;
          color: #333333;
          margin-top: 5px;
        }
        .subtitle{
          margin-top: 5px;
          font-size: 12px;
          font-weight: 500;
          color: #666;
        }
      }
    }
}
    


.assessment-main-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Baloo 2';
    padding: 20px 20px;
    z-index: 1000;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    animation: slideUp 0.7s ease-out forwards;
    &::-webkit-scrollbar {
      display: none;
    }
    .more-coins{
      padding: 15px 15px;
      border-radius: 24px;
      background-color: #ebf8fb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-top: 20px;
      .text{
        width: 50%;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #333333;
        text-align: left;
      }
    }
    .story-recommendation{
      width: 100%;
      text-align: left;
      margin-top: 30px;
      .text{
        font-size: 20px;
        font-weight: 600;
      }
      .story-list-wrapper{
        overflow-x: scroll;
        overflow-y: hidden;
        padding-bottom: 20px;
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
        .story-list{
          display: flex;
          margin-top: 6px;
          justify-content: flex-start;
          margin-bottom: 10px;
          .story-thumbnail{
             width: 210px;
             border-radius: 20px;
             margin-right: 20px;
             box-shadow: 0 8px 14px 0 rgba(164, 137, 237, 0.16);
             background-color: white;
             overflow:hidden;
             position: relative;
             .difficulty-tag{
              position: absolute;
              top:170px;
              right: 0;
              min-width: 93.7px;
              padding: 7px;
              transform: rotate(-357deg);
              border-radius: 10px;
              background-image:linear-gradient(89deg, #0ec6b5 -38%, #51acff 116%);
              z-index: 1200;
              color: white;
              text-align: center;
             }
             .story-desc{
               padding: 15px 10px 10px 10px;
               font-family: "Baloo 2";
               font-size: 19px;
               font-weight: 800;
               color: #333333;
               .story-stars{
                 display: flex;
                 align-items: center;
                 font-size: 12px;
                 font-weight: 500;
                 color: #666;
                 .stars-count{
                   margin-left: 8px;
                 }
               }
             }
             .story-image{
               width: 120%;
               height: 190px;
               border-radius: 20px;
               margin-right: 10px;
               object-fit: cover;
               transform: rotate(-5deg);
             }
          }
        }
      }
    }
}

@keyframes slideUp{
  0%{
    margin-top: 70vh;
    opacity: 0;
  }
  100%{
    margin-top:0;
    opacity: 1;
  }
}


@keyframes zoomIn{
  0%{
    transform: scale(0.1);
    opacity: 0;
  }
  100%{
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes growDown{
  0%{
    height: 10rem;
  }
  100%{
    height: 23.12rem;
  }
}


@keyframes rotateSunRays{
  0%{
    transform: rotate(0deg);
    // opacity: 0;
  }
  // 40%{
  //   opacity: 1;
  // }
  100%{
    transform: rotate(360deg);
    // opacity: 1;
  }
}


@keyframes growFurther{
  0%{
    height: 23.12rem;
  }
  100%{
    height: 28.12rem;
  }
}