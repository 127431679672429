.header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1.5rem;
  padding-top: constant(
    safe-area-inset-top
  ); /* Status bar height on iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
  align-items: center;
  .header-box {
    display: flex;
    flex: 0 0 auto;
    height: 2.5rem;
    width: 2.5rem;
    svg {
      height: 100%;
      width: auto;
    }
  }
  .header-content {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
  }
}
