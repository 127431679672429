.text-wrapper {
  color: #ffffff;
  align-self: center;
  width: 100%;
  word-break: break-all;

  &.text-center {
    justify-content: center;
    align-self: center;
    text-align: center;
  }
  &.text-left {
    justify-content: left;
    text-align: left;
  }
  .right {
    color: green;
  }
}

.page-content{
  height: 100vh;
  .image{
    width: 100%;
    height: 30vh;
    border-radius: 15px;
    object-fit: cover;
  }
  .words{
    padding: 0.62rem 0;
    margin-left: -0.62rem;
    display: flex;
    flex-wrap: wrap;
    font-family: 'Baloo 2';
    font-size: 19px;
    font-weight: 800;
    color:#333333;
    .word{
      margin-left: 0.62rem;
      position: relative;
      .coin{
        display: none;
      }
      .coin-lottie{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .hide{
      display: none;
    }
    .prompt{
      -webkit-text-stroke: 1px #fbffca;
      background-image: linear-gradient(to top, #debb00, #de8500);
      text-align: left;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .correct{
      color:#0099ba;
      .coin{
          display: block;
          position: absolute;
          top: -30%;
          left: 50%;
          transform: translateX(-50%);
          width: 20px;
          height: 20px;
          z-index: 111;
      }
      .coin-show-animation{
        animation: showCoin 2s ease-out forwards;
      }
    }
    .wrong{
      text-decoration: underline;
      color: #e13b77;
    }
  }
  @media (min-width: 700px) { 
    .words{
      font-size: 28px;
    }
  }
}

@keyframes showCoin{
  0%{
    opacity: 0;
  }
  50%{
   opacity:1;
  }
  100%{
    opacity:0;
  }
}

.dN{
  display: none;
}